import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import {
  StoresFilter,
  TStaffListItem,
  TStoresListItem,
  useConcludeWalletsManagementMutation,
  useGetStaffListInfinityQuery,
  useGetStoresListInfinityQuery,
  useGetWalletsManagementDetailsQuery
} from 'integration/resources/walletsManagement'
import { useBreakpointValue, useDisclose, useToast } from 'native-base'
import { Toast } from 'organisms'
import useDebounce from 'src/hooks/useDebounce'
import { useAuthAtomValue } from 'src/store/auth'

import { OrderField, Tab, UseStaffListScreen } from './StaffListScreen.types'

export const orderFields: OrderField[] = [
  {
    label: 'Nome',
    options: [
      { id: 'asc', name: 'A-Z' },
      { id: 'desc', name: 'Z-A' }
    ]
  },
  {
    label: 'Número de TABs',
    options: [
      { id: 'asc', name: 'Menor para o Maior' },
      { id: 'desc', name: 'Maior para o menor' }
    ]
  }
]

const mergeStaffInfiniteQuery = (data: ReturnType<typeof useGetStaffListInfinityQuery>['data']) =>
  data?.pages.reduce<TStaffListItem[]>(
    // @ts-ignore
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

const mergeStoresInfiniteQuery = (data: ReturnType<typeof useGetStoresListInfinityQuery>['data']) =>
  data?.pages.reduce<TStoresListItem[]>(
    // @ts-ignore
    (previousValue, currentValue) => [...previousValue, ...currentValue.data.data],
    []
  ) ?? []

export const useStaffListScreen: UseStaffListScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Specialists)

  const [timeouts, setTimeouts] = useState<number[]>([])

  const [search, onSearch] = useState<string>()

  const debouncedSearch = useDebounce(search, 500)

  const [selectedOrderBy, setSelectedOrderBy] = useState<{
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }>({
    field: undefined,
    direction: undefined
  })

  const authAtom = useAuthAtomValue()

  const userStdCode = useMemo(() => authAtom?.user?.std_code ?? 0, [authAtom?.user?.std_code])

  const {
    data: staffListData,
    isLoading: staffListLoading,
    refetch: staffListRefetch
  } = useGetStaffListInfinityQuery({
    leader_std_code: userStdCode,
    order_by: selectedOrderBy?.field === 'Nome' ? 'name' : 'stores_quantity',
    order_by_direction: selectedOrderBy?.direction,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const staffData = useMemo(() => mergeStaffInfiniteQuery(staffListData), [staffListData])

  const { data: storesListData, isLoading: storesListLoading } = useGetStoresListInfinityQuery({
    leader_std_code: userStdCode,
    order_by: selectedOrderBy?.field,
    order_by_direction: selectedOrderBy?.direction,
    filter_by:
      currentTab === Tab.AssignedTabs
        ? StoresFilter.AssignedTabs
        : currentTab === Tab.UnassignedTabs
        ? StoresFilter.UnassignedTabs
        : undefined,
    ...(debouncedSearch?.length && { search: debouncedSearch })
  })

  const storesData = useMemo(() => mergeStoresInfiniteQuery(storesListData), [storesListData])

  const { data: walletsManagementDetailsData, isLoading: walletsManagementDetailsIsLoading } =
    useGetWalletsManagementDetailsQuery(userStdCode)

  const detailsData = walletsManagementDetailsData?.data.data

  const { mutate: concludeMutate, isLoading: concludeIsLoading } =
    useConcludeWalletsManagementMutation()

  const handleTabChange = (tab: Tab) => {
    setCurrentTab(tab)
  }

  const {
    isOpen: manageWalletModalIsOpen,
    onClose: manageWalletModalOnClose,
    onOpen: manageWalletModalOnOpen
  } = useDisclose()

  const {
    isOpen: concludeModalIsOpen,
    onClose: concludeModalOnClose,
    onOpen: concludeModalOnOpen
  } = useDisclose()

  const toast = useToast()

  const handleConclude = useCallback(() => {
    const clearAllTimeouts = () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))

      setTimeouts([])
    }

    concludeMutate(
      { leader_std_code: userStdCode },
      {
        onError() {
          const timeoutId = window.setTimeout(() => {
            concludeModalOnClose()

            clearAllTimeouts()
          }, 1000)

          setTimeouts((prev) => [...prev, timeoutId])

          toast.show({
            render: () => <Toast type="error" text="Não foi possível completar a ação " />,
            placement: 'bottom',
            duration: 1500
          })
        },
        onSuccess() {
          const timeoutId = window.setTimeout(() => {
            concludeModalOnClose()

            clearAllTimeouts()
          }, 1000)

          setTimeouts((prev) => [...prev, timeoutId])

          toast.show({
            render: () => <Toast type="success" text="Carteirização concluída 🎉" />,
            placement: 'bottom',
            duration: 1500
          })
        }
      }
    )
  }, [concludeMutate, concludeModalOnClose, userStdCode, toast, timeouts])

  const { isOpen: orderByIsOpen, onOpen: orderByOnOpen, onClose: orderByOnClose } = useDisclose()

  const handleCheckboxChange = (field: string, direction: 'asc' | 'desc') => {
    setSelectedOrderBy({ field, direction })
  }

  const handleApply = () => staffListRefetch()

  const handleClear = () => {
    setSelectedOrderBy({ field: undefined, direction: undefined })
  }

  useEffect(() => {
    return () => {
      timeouts.forEach((timeoutId) => clearTimeout(timeoutId))
    }
  }, [timeouts])

  const calculateTotalStores = (staffList: TStaffListItem[]): number => {
    return staffList.reduce((sum, leader) => {
      return sum + Number(leader.qtd_stores)
    }, 0)
  }

  const unassignedStoresData = storesData.filter((item) => !item.specialist)

  const unassignedStores = unassignedStoresData.length

  const handleGoToConclude = useCallback(() => {
    concludeModalOnClose()

    linkTo(`/carteirizacao/concluir/${userStdCode}`)
  }, [linkTo, userStdCode, concludeModalOnClose])

  return {
    isMobile,
    currentTab,
    staffData,
    listIsLoading: staffListLoading,
    storesData,
    storesListIsLoading: storesListLoading,
    detailsData,
    detailsIsLoading: walletsManagementDetailsIsLoading,
    handleTabChange,
    concludeModalIsOpen,
    concludeModalOnClose,
    concludeModalOnOpen,
    handleConclude,
    concludeIsLoading,
    search,
    onSearch,
    handleClear,
    handleApply,
    handleCheckboxChange,
    selectedOrderBy,
    orderByIsOpen,
    orderByOnOpen,
    orderByOnClose,
    calculateTotalStores,
    manageWalletModalIsOpen,
    manageWalletModalOnClose,
    manageWalletModalOnOpen,
    unassignedStoresData,
    unassignedStores,
    handleGoToConclude
  }
}
