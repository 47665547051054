import { FunctionComponent, useEffect, useMemo } from 'react'

import { useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { IconButton } from 'native-base'
import { RootStackParamList } from 'navigation/types'

import Icons from './Icons'

export const ButtonBackRightSheet: FunctionComponent = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const route = useRoute()

  const routes = useMemo(() => {
    const initialRoute = {
      NotificationDetail: {
        name: 'Notification',
        path: '/notificacoes'
      },
      Content: {
        name: 'HomeContent',
        path: '/conteudos'
      },
      EditProfile: {
        name: 'Profile',
        path: '/perfil'
      },
      OrganizationActivity: {
        name: 'OrganizationActivityList',
        path: '/minha-organizacao/tarefas'
      },
      OrganizationActivityEdit: {
        name: 'OrganizationActivityList',
        path: '/minha-organizacao/tarefas'
      },
      OrganizationOpportunity: {
        name: 'OrganizationOpportunityList',
        path: '/minha-organizacao/oportunidades/5'
      },
      OpportunityFilter: {
        name: 'OrganizationOpportunityList',
        path: '/minha-organizacao/oportunidades/5'
      },
      SurveyDetail: {
        name: 'Survey',
        path: '/pesquisas'
      },
      TabEditNoteDetails: {
        name: 'WalletTabList',
        path: '/carteira/tabs'
      },
      TabCreateNoteDetails: {
        name: 'WalletTabList',
        path: '/carteira/tabs'
      },
      ContactEditNoteDetails: {
        name: 'WalletContactList',
        path: '/carteira/contatos'
      },
      ContactCreateNoteDetails: {
        name: 'WalletContactList',
        path: '/carteira/contatos'
      },
      ContactCreate: {
        name: 'WalletContactList',
        path: '/carteira/contatos'
      },
      ContactEdit: {
        name: 'WalletContactList',
        path: '/carteira/contatos'
      },
      Production: {
        name: 'ProductionChart',
        path: '/carteira/producao'
      },
      PreferencesProfile: {
        name: 'Profile',
        path: '/perfil'
      },
      ContractList: {
        name: 'Home',
        path: '/home'
      },
      About: {
        name: 'Profile',
        path: '/perfil'
      },
      LeadsApnef: {
        name: 'OrganizationOpportunityList',
        path: '/minha-organizacao/oportunidades/5'
      },
      Recommendations: {
        name: 'OrganizationOpportunityList',
        path: '/minha-organizacao/oportunidades/5'
      },
      ConcludeManagement: {
        name: 'StaffList',
        path: '/carteirizacao-lider'
      },
      default: {
        name: 'Home',
        path: '/home'
      }
    }

    return initialRoute
  }, [])

  const handleGoBack = () => {
    // @ts-ignore
    const routeSelected = routes[route.name] ?? routes['default']

    if (navigation.canGoBack()) {
      if (routeSelected?.type === 'tab') {
        navigation.navigate('Tab', {
          screen: routeSelected.name
        })
      } else {
        navigation.goBack()
      }
    } else {
      navigation.reset({
        routes: [
          {
            name: 'Home',
            path: '/home'
          }
        ]
      })
    }
  }

  /**
   * @TODO
   * Adicionar regras conforme telas necessárias
   * a tela home, no caso será a tela que fica por baixo
   * da RightSheet, caso necessite de uma diferente precisa ser tratado
   */
  useEffect(() => {
    if (!navigation.canGoBack()) {
      //@ts-ignore
      const routeSelected = routes[route.name] ?? routes['default']

      navigation.reset({
        index: 1,
        routes: [
          //@ts-ignore
          { ...routeSelected, params: route.params },
          {
            name: route.name as keyof RootStackParamList,

            params: route.params
          }
        ]
      })
    }
  }, [navigation, route, routes])

  return (
    <IconButton
      bg="gray.50"
      _hover={{
        bg: 'gray.100'
      }}
      _pressed={{
        bg: 'gray.100'
      }}
      w={10}
      h={10}
      borderRadius="full"
      onPress={handleGoBack}
      icon={<Icons.Close size={3} color="black" />}
    />
  )
}
