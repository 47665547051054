export type TParams = {
  leader_std_code?: number
  page?: number
  per_page?: number
  order_by?: string
  order_by_direction?: string
  search?: string
  enabled?: boolean
  filter_by?: StoresFilter
}

export type TConcludeParams = {
  leader_std_code: number
  stores?: {
    store_tab_number: number
    description: string
  }[]
}

export enum StoresFilter {
  AssignedTabs,
  UnassignedTabs
}

export type TStaffListItem = {
  user_std_code: number
  user_name: string
  occupation: string
  qtd_stores: string
  profile_image_file_name: string
}

export type TLeadersListItem = {
  user_name: string
  occupation: string
  network: string
  user_std_code: string
  subsidiary: string
  qtd_staff: number
  qtd_stores: number
  profile_image_file_name: string
}

export type TStoresListItem = {
  store_name: string
  store_tab_number: number
  segment: string
  store_image_file_name: string
  specialist?: string
  assigned_by?: string
  assigned_date?: string
}

export type TWalletsManagementDetails = {
  is_open: boolean
  reactivated: boolean
  openingDate: string
  closingDate: string
  specialists: {
    total: number
    assigned: number
  }
  tabs: {
    total: number
    assigned: number
  }
}
