import { FunctionComponent } from 'react'

import {
  TStaffListItem,
  TStoresListItem,
  TWalletsManagementDetails
} from 'integration/resources/walletsManagement'
import { RootStackScreenComponentProps } from 'src/navigation'

export type StaffListScreenComponent = FunctionComponent<StaffListScreenProps>

export type StaffListScreenProps = RootStackScreenComponentProps<'StaffList'>

export type ResponsiveStaffListScreenComponent = FunctionComponent<ReturnType<UseStaffListScreen>>

export type CardProps = TStaffListItem

export enum Tab {
  Specialists,
  AssignedTabs,
  UnassignedTabs
}

export type TabButtonProps = {
  tab: Tab
  label: string
  currentTab: Tab
  onPress: (tab: Tab) => void
}

export type OrderOption = {
  id: 'asc' | 'desc'
  name: string
}

export type OrderField = {
  label: string
  options: OrderOption[]
}

export type OrderByButtonProps = {
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
}

export type UseStaffListScreen = ({
  navigation,
  route
}: Pick<StaffListScreenProps, 'navigation' | 'route'>) => {
  isMobile: boolean
  onSearch?(search: string): void
  search?: string
  onPressSearch?: () => void
  onPressSearchClear?: () => void
  staffData?: TStaffListItem[]
  listIsLoading: boolean
  storesData?: TStoresListItem[]
  storesListIsLoading: boolean
  detailsData?: TWalletsManagementDetails
  detailsIsLoading: boolean
  handleTabChange: (tab: Tab) => void
  currentTab: number
  concludeModalIsOpen: boolean
  concludeModalOnClose: () => void
  concludeModalOnOpen: () => void
  handleConclude: () => void
  concludeIsLoading: boolean
  handleCheckboxChange: (field: string, direction: 'asc' | 'desc') => void
  handleClear: () => void
  handleApply: () => void
  selectedOrderBy: {
    field: string | undefined
    direction: 'asc' | 'desc' | undefined
  }
  orderByIsOpen: boolean
  orderByOnOpen: () => void
  orderByOnClose: () => void
  calculateTotalStores: (staffList: TStaffListItem[]) => number
  manageWalletModalIsOpen: boolean
  manageWalletModalOnClose: () => void
  manageWalletModalOnOpen: () => void
  unassignedStoresData?: TStoresListItem[]
  unassignedStores: number
  handleGoToConclude: () => void
}
