export default {
  AcceptTerms: 'Aceite de termos',
  HomeContent: 'Listagem de conteúdos',
  Content: 'Detalhe conteúdo',
  ContentByCategory: 'Conteúdo por categoria',
  RefuseTerms: 'Recusa de termos',
  CreateAccountCPF: 'Criar conta - CPF',
  CreateAccountPhoneEmail: 'Criar conta - Telefone/E-mail',
  CreateAccountCreatePassword: 'Criar conta - criar senha',
  CreateAccountAuthCode: 'Criar conta - Validar token',
  CreateAccountSendEmailConfirmation: 'Criar conta - E-mail de confirmação',
  CreateAccountChangeEmail: 'Criar conta - Alterar e-mail',
  CreateAccountConfirmEmail: 'Criar conta - Confirmar e-mail',
  Entry: 'Tela inicial',
  Home: 'Home',
  Notification: 'Notificação',
  Login: 'Login',
  LoginBlocked: 'Login bloqueado',
  LoginAccountReview: 'Login - Revisar conta',
  LoginAuthCode: 'Login - Confirmar Token',
  Onboarding: 'Onboarding',
  Profile: 'Perfil',
  ContactDetails: 'Detalhes do contato',
  ContactEdit: 'Contato - Editar',
  ContactCreate: 'Criar contato',
  ContactBirthday: 'Contato - Data de aniversário',
  ContactEditBirthday: 'Contato - Editar data de aniversário',
  TabSearch: 'TAB - Busca',
  TabFilter: 'TAB - Filtro',
  TabRelated: 'TABs relacionadas',
  ContactSearch: 'Contato - Busca',
  ContactFilter: 'Contato - Filtro',
  EditProfile: 'Perfil - Editar',
  OptionsProfile: 'Perfil - Opções',
  SecurityProfile: 'Perfil - Segurança',
  Lockscreen: 'Perfil - Bloqueio tela',
  PreferencesProfile: 'Perfil - Preferências',
  Terms: 'Termos',
  DeviceManagement: 'Lista de Dispositivos',
  IntegrationProfile: 'Perfil - Integrações',
  UpdateAvatarProfile: 'Perfil - Alterar Avatar',
  Simulator: 'Simulador',
  MoreModules: 'Mais Menus',
  TabDetails: 'TAB - Detalhes',
  TabEdit: 'TAB - Editar',
  TabEditNoteDetails: 'TAB - Editar Nota',
  TabLocation: 'TAB - Endereço',
  TabCreateNoteDetails: 'TAB - Nota Criar',
  ContactCreateNoteDetails: 'Contato - Nota Criar',
  ContactEditNoteDetails: 'Contato - Editar Nota',
  WalletContactList: 'Carteira - Contatos',
  WalletTabList: 'Carteira - TABs',
  Wallet: 'Carteira',
  WalletTab: 'Carteira Detalhes - TABs',
  WalletContact: 'Carteira Detalhes - Contato',
  ContactList: 'Lista de Contatos',
  ResetPasswordEmailSentSuccessfully: 'Reset Senha - E-mail enviado',
  ResetPasswordSendEmail: 'Reset Senha - Enviar e-mail',
  ResetPasswordCreatePassword: 'Reset Senha - Criar senha',
  ResetPasswordAuthCode: 'Reset Senha - Validar token',
  ResetPasswordSuccessfully: 'Reset Senha - Sucesso',
  ResetPasswordReSentEmail: 'Reset Senha - Re-enviar e-mail',
  Opportunity: 'Oportunidades',
  OpportunityDetails: 'Oportunidade - Detalhes',
  NotificationDetail: 'Notificação - Detalhes',
  FirstLoginPassword: 'Primeiro Login - Senha',
  FirstLoginSuccess: 'Primeiro Login - Sucesso',
  FirstLoginAuthCode: 'Primeiro Login - Validar Token',
  ErrorPageNotFound: '404',
  ErrorPageNoConnection: 'Sem Conexão',
  ErrorPageErrorOccurred: 'Erro Ocorrido',
  CapturePhoto: 'Caputrar Foto',
  NoteDetails: 'Nota - Detalhes',
  EditNoteDetails: 'Nota - Editar',
  CreateNoteDetails: 'Nota - Criar',
  TabContact: 'TAB - Contato',
  Organization: 'Organização',
  OrganizationActivityList: 'Organização - Tarefas',
  OrganizationActivity: 'Organização - Detalhe tarefa',
  OrganizationActivityEdit: 'Organização - Editar tarefa',
  OrganizationActivityCreate: 'Organização - Criar tarefa',
  OrganizationOpportunityList: 'Organização - Oportunidades',
  OrganizationOpportunity: 'Organização - Detalhe Oportunidade',
  OpportunityFilter: 'Oportunidade - Filtro',
  ProductionChart: 'Gráfico Produção',
  Chart: 'Gráfico',
  Production: 'Produção',
  RemoveAccountConfirm: 'Remover conta - Confirmar',
  About: 'Sobre',
  ManageNotifications: 'Gerenciar Notificações',
  SummaryDetail: 'Detalhe do resumo',
  Chat: 'Atendimento Chat',
  ContractList: 'Lista de Contratos',
  Survey: 'Pesquisas',
  SurveyDetail: 'Detalhe da pesquisa',
  Dashboard: 'Painéis',
  DashboardDetail: 'Detalhe do painel',
  LeadsApnef: 'Leads e APNEF',
  Recommendations: 'Recomendações',
  StaffList: 'Carteirização - Líder',
  LeadersList: 'Carteirização - Admin',
  HirePlus: 'Contrata+',
  ConcludeManagement: 'Concluir Carteirização'
}
